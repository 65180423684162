import request from './request'




export function login(data) {
  return request({
    url: `https://apipro.guolaoai.com/trademark/service/data/login`,
    method:'POST',
    data
  })
}

/**
 *  @title 知产管理 数据统计
 */
export function getUserKnowledgeMainTrademarkCount(data) {
  return request({
    url: `${process.env.VUE_APP_API}/trademark/service/ai/getUserKnowledgeMainTrademarkCount`,
    method:'POST',
    data
  })
}

/**
 *  @title 知产管理  展业提醒明细和数据列表
 */
export function knowledgeAllZhangYe(data) {
  return request({
    url: `${process.env.VUE_APP_API}/trademark/service/ai/knowledgeAllZhangYe`,
    method:'POST',
    data
  })
}

/**
 *  @title 公司 商标 统计
 */
export function getIntClsCount(data) {
  return request({
    url: `${process.env.VUE_APP_API}/trademark/service/ai/getIntClsCount`,
    method:'POST',
    data
  })
}

/**
 *  @title 查询认证企业
 */
export function getAuthInfos(data) {
  return request({
    url: `${process.env.VUE_APP_API}/user/service/cooperator/getBusinessList`,
    method:'POST',
    data
  })
}


/**
 *  @title 商标申请进度
 */
export function queryTrademarkProgressList(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/SaaS/getSaaSApplicationPro',
    method:'POST',
    data
  })
}


/**
 *  @title 商标 详情
 */
export function knowledgeDetail(data) {
  return request({
    url: `${process.env.VUE_APP_API}/trademark/service/ai/knowledgeDetail`,
    method:'POST',
    data
  })
}


/**
 *  @title 公告图片
 */
export function knowledgeAnnouncement(data) {
  return request({
    url: `${process.env.VUE_APP_API}/trademark/service/ai/knowledgeAnnouncement`,
    method:'POST',
    data
  })
}


/**
 *  @title 获取监控列表
 */
 export function getWarnlist(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/knowledgeMonitorCompany',
    method:'POST',
    data
  })
}

/**
 *  @title 获取单个监控商标
 */
 export function getWarnevery(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/knowledgeMonitorCompanySelect',
    method:'POST',
    data
  })
}

/**
 *  @title 驰名商标列表
 */
 export function famous(data) {
  return request({
    url: `${process.env.VUE_APP_API}/user/service/cooperator/getFamousTrademarkList`,
    method:'POST',
    data
  })
}
/**
 *  @title 专家
 */
 export function getport(data) {
  return request({
    url: `${process.env.VUE_APP_API}/user/service/cooperator/getSpecialistList`,
    method:'POST',
    data
  })
}

/**
 *  @title 根据商标名称获取商标类别
 */
 export function getcate(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/SaaS/getBrandCategoryList',
    method:'POST',
    data
  })
}

/**
 *  @title 根据商标名称获取商标状态
 */
 export function getstatus(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/SaaS/getBrandTypeList',
    method:'POST',
    data
  })
}

/**
 *  @title 根据商标名称、大类、商标状态查询列表(所有商标)
 */
 export function getall(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/SaaS/getUnifiedManageBrandList',
    method:'POST',
    data
  })
}

/**
 *  @title 根据商标名称、商标状态查询列表(申请中商标)
 */
 export function getapplyall(data) {
  return request({
    url: 'https://aipro.guolaoai.com/search/SaaS/getApplicationProgress',
    method:'POST',
    data
  })
}
/**
 *  @title 一进商标管理调用
 */
 export function getfirst(data) {
  return request({
    url: `${process.env.VUE_APP_API}/user/service/cooperator/firstCompanyAuth`,
    method:'POST',
    data
  })
}

export const showAll = (data) => {
  return request({
    method: 'POST',
    url: 'https://4n079760m8.imdo.co/shop',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const shopEmpower = (data) => {
  return request({
    method: 'POST',
    url: 'https://4n079760m8.imdo.co/shopEmpower',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const shopUnempower = (data) => {
  return request({
    method: 'POST',
    url: 'https://4n079760m8.imdo.co/shopUnempower',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const empower = (data) => {
  return request({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/empower',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const unEmpower = (data) => {
  return request({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/unEmpower',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const goodsAll = (data) => {
  return request({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/goods',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const goodsEmpower = (data) => {
  return request({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/goodsEmpower',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}

export const goodsUnempower = (data) => {
  return request({
    method: 'post',
    url: 'https://4n079760m8.imdo.co/goodsUnempower',
    data: data,
    headers: {
      "Content-Type": "application/json",
    }
  })
}